@import "../../all-modules.global";

.modal {
  $cross-size: 24px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  justify-content: center;
  font-weight: 400;
  transition: opacity 0.2s linear;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);

  &_open:not(:focus-within) {
    background-color: rgba(0, 0, 0, 0.51);
    transition: background-color 0.01s, opacity 0.3s linear;
  }

  &_open {
    pointer-events: auto;
    opacity: 1;
    // needed to scroll if screen size lower than modal's height
    max-width: 100%;
    overflow: scroll;
  }

  &__body {
    width: 100%;

    &_size_s {
      @include font-size(s);
    }

    &_size_sm {
      @include font-size(sm);
    }

    &_size_sm:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__body:last-child {
    margin-bottom: 52px - 24px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 $cross-size 0;
    position: relative;
    width: 100%;
  }

  &__title {
    @include font-size(l);
    line-height: 36px;
    font-weight: 500;
    margin: 12px 0 0 0;
    // ellipsis if the title is too long
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-wrap: normal;
  }

  &__content {
    background-color: var(--card);
    border-color: transparent;
    padding: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    width: 420px;
    max-width: 100%;
    transition: top .3s ease-out;
    align-items: center;
    z-index: 1050;
    margin: auto;
    top: 50px;
    white-space: break-spaces;

    &:focus-visible {
      outline: none;
    }
  }

  &_open &__content {
    top: 0;
  }

  &__close {
    --color: var(--grey);
    background: transparent;
    box-shadow: none;
    text-shadow: none;
    position: relative;
    z-index: 10;
    border: 0;
    cursor: pointer;
    width: $cross-size;
    height: $cross-size;
    padding: 2px;
    align-self: flex-start;

    &::before, &::after {
      content: '';
      position: absolute;
      width: 3px;
      border-radius: 2px;
      height: 100%;
      top: 0;
      left: 11px;
      background: var(--color);
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }

  }

  &__close:hover, &__close:focus {
    --color: var(--dark-red);
  }

  img {
    width: 100%;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin: 8px 0 8px 0;
    gap: 8px;
    width: 100%;
    flex-wrap: wrap;

    label, button:not(:only-child) { // Otherwise it stretches to take up the entire footer
      width: auto;
      margin: 0;
      flex: 1 1 auto; /* Automatically adjusts based on available space if flex-wrap was applied */
    }
  }

  @media (max-width: $mobile-width) {
    &_stretch {
      overflow-y: scroll;
    }

    &_stretch &__content {
      min-height: 100vh;
      border-radius: 0;
      width: 100%;
    }
  }
}
