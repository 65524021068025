.alert {
  padding: 12px 12px 12px 24px + 12px + 8px;
  border-radius: 4px;
  position: relative;
  border: 1px solid var(--blue-dark);
  min-width: fit-content;
  max-width: 100%;

  &::before {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    left: 12px;
  }

  &_type_info {
    border-color: var(--blue-dark);
    &::before {
      background-color: var(--blue-dark);
      mask: url("/static/img/info.svg");
    }
  }

  &_type_error {
    border-color: var(--red);
    &::before {
      background-color: var(--red);
      mask: url("/static/img/error-bell.svg");
    }
  }

  &_type_success {
    border: 1px solid var(--green);
    padding-left: 24px + 12px + 8px;
    &::before {
      background-color: var(--green);
      mask: url("/static/img/success.svg");
    }
  }
}
